import React from 'react'
import { Link } from 'gatsby'
import { Parallax } from 'react-parallax'
import ReactWOW from 'react-wow';
const Banner = (props) => {
    return(
        <section className="breadcrumb_area_two">
            {/* <ul className="list-unstyled bubble">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul> */}
            <div className="breadcrumb_shap"></div>
            <div className="container">
                <div className="general_banner_content breadcrumb_content_two text-center ">
                    {/* <h5 className='resizeHeading'>{props.bannerContent.categories}</h5> */}
                    <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20 resize_blog_media">{props.bannerContent.title}</h1>
                    {(props.bannerContent.author) ? <ol className="list-unstyled">
                        <li>{props.bannerContent.author}</li>
                        <li>{props.bannerContent.date}</li>
                    </ol> : ''}
                </div>
            </div>
        </section>
    )
}

export default Banner

