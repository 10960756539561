import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/layouts"
import Banner from "../../../components/resources/blog/Banner"
import { Disqus } from "gatsby-plugin-disqus"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../../utils/linkResolver"
import styled from "styled-components"

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share"
import SEO from "../../../components/common/SEO"

// Query

export const query = graphql`
  query BlogDetailQuery(
    $uid: String
    $paginationPreviousUid: String!
    $paginationNextUid: String!
  ) {
    seo: site {
      siteMetadata {
        url
        twitterUsername
      }
    }
    nextArticle: prismic {
      blog(lang: "en-us", uid: $paginationNextUid) {
        thumbnail
        title
        date_created
        social_image
        _meta {
          uid
          lang
          type
        }
      }
    }
    prevArticle: prismic {
      blog(lang: "en-us", uid: $paginationPreviousUid) {
        thumbnail
        title
        date_created
        social_image
        _meta {
          uid
          lang
          type
        }
      }
    }
    blogDetail: prismic {
      allBlogs(uid: $uid, sortBy: meta_firstPublicationDate_DESC) {
        totalCount
        edges {
          node {
            _linkType
            title
            social_description
            date_created
            thumbnail
            _meta {
              uid
            }
            author {
              ... on PRISMIC_Author {
                meta_title
                last_name
                first_name
                description
                social_image
              }
            }
            meta_description
            meta_title
            social_title
            social_image
            social_description
            categories {
              blog_category {
                ... on PRISMIC_Category {
                  title
                  _meta {
                    uid
                  }
                }
              }
            }
            body {
              ... on PRISMIC_BlogBodyQuote {
                type
                label
                primary {
                  text
                  author1
                }
              }
              ... on PRISMIC_BlogBodyText {
                type
                label
                primary {
                  richtext
                }
              }
              ... on PRISMIC_BlogBodyImage {
                type
                label
                primary {
                  image
                }
              }
              ... on PRISMIC_BlogBodyImage_gallery {
                type
                label
                primary {
                  name_of_the_gallery
                }
              }
              ... on PRISMIC_BlogBodyCall_to_action {
                type
                primary {
                  cta_text
                  cta_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  button_title
                }
              }
              ... on PRISMIC_BlogBodyBottom_call_to_action {
                type
                label
                primary {
                  bottom_cta_text
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
    recentBlogs: prismic {
      allBlogs(first: 3, sortBy: date_created_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            title
            thumbnail
            date_created
          }
        }
      }
    }
    categoryList: prismic {
      allCategorys {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Blog = props => {

  // Styled Components
  const NextPrev = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
    @media (max-width: 664px) {
      align-items: column;
      justify-content: center;
    }

    .pagContain {
      flex: 0 1 100%;
      padding: 0.5rem;
      @media (min-width: 40em) {
        flex: 0 1 calc(50% - 10px);
      }
      @media (min-width: 60em) {
        flex: 0 1 calc(375px - 10px);
      }
      h6 {
        margin-bottom: 0px;
        font-size: 1.2rem;
        a {
          color: black;
        }
        a:hover {
          color: #f05123;
        }
      }
    }
    .thumb {
      height: 300px;
      background: blue;
      margin-bottom: 1rem;
      border-radius: 8px;
      border: solid #f05123 3px;
      position: relative;
      @media (max-width: 450px) {
        height: 150px;
      }
    }
    /* .greyLine {
      height: 300px;
      width: 3px;
      background-color: #333;
    } */
  `

  const PrevButton = styled.button`
    position: absolute;
    top: 45%;
    left: -30px;
    color: white;
    background-color: #f05123;
    border-radius: 50%;
    text-align: center;
    height: 40px;
    width: 40px;
    a {
      color: white;
    }
  `

  const NextButton = styled.button`
    position: absolute;
    top: 45%;
    right: -30px;
    color: white;
    background-color: #f05123;
    border-radius: 50%;
    text-align: center;
    height: 40px;
    width: 40px;
    a {
      color: white;
    }
  `

  const ThumbPrev = styled.div`
      height: 300px;
      width: 100%;
      background-image: ${p => p.prevArticle.blog.thumbnail ? `url(${prevArticle.blog.thumbnail.url})` : `url(/about/team-harshil.jpg)`};
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem;
      border-radius: 8px;
      border: solid #f05123 3px;
      position: relative;
      @media (max-width: 450px) {
        height: 150px;
      }
`

  const ThumbNext = styled.div`
    height: 300px;
    width: 100%;
    background-image: ${p => p.nextArticle.blog.thumbnail ? `url(${nextArticle.blog.thumbnail.url})` : `url(/about/team-harshil.jpg)`};
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: solid #f05123 3px;
    position: relative;
    @media (max-width: 450px) {
      height: 150px;
    }
`
// End Styled Components

  // Enhanced Pagination

  const {
    pageContext: { paginationPreviousMeta, paginationNextMeta },
    data: { prevArticle, nextArticle },
  } = props

  const EnhancedPagination = ({ nextArticle, prevArticle }) => (
    <NextPrev>
      {prevArticle.blog !== null ? (
        <div className="pagContain">
          <Link
            to={linkResolver(prevArticle.blog._meta) + "/"}
            aria-label="Previous Post"
          >
            <ThumbPrev prevArticle={prevArticle} alt={prevArticle.blog.thumbnail.alt}>
              <PrevButton>
                  &larr;
              </PrevButton>
            </ThumbPrev>
          </Link>

          <h6>
            <Link
              to={linkResolver(prevArticle.blog._meta) + "/"}
              aria-label="Next Post"
            >
              {RichText.asText(prevArticle.blog.title || [], linkResolver)}
            </Link>
          </h6>
          {/* <p style={{ fontSize: "14px", marginBottom: '0.5rem' }}>{prevArticle.blog.date_created}</p> */}
        </div>
      ) : null}
      {nextArticle.blog !== null ? (
        <div className="pagContain" id="pagLeft">
          <Link
            to={linkResolver(nextArticle.blog._meta) + "/"}
            aria-label="Next Post"
          >
            <ThumbNext nextArticle={nextArticle} alt={nextArticle.blog.thumbnail.alt}>
              <NextButton>
                  &rarr;
              </NextButton>
            </ThumbNext>
          </Link>
          <h6>
            <Link
              to={linkResolver(nextArticle.blog._meta) + '/'}
              aria-label="Next Post"
            >
              {RichText.asText(nextArticle.blog.title || [], linkResolver)}
            </Link>
          </h6>
          {/* <p style={{ fontSize: "14px", marginBottom: '0.5rem' }}>{nextArticle.blog.date_created}</p> */}
        </div>
      ) : null}
    </NextPrev>
  )

  //  SEO & Banner Content

  const blog = props.data.blogDetail.allBlogs

  let categoryString = ""
  let bannerContent = {
    categories: "categoryString",
    title: "content.title[0].text",
    author: "content.author.first_name +  + content.author.last_name",
    date: "blogMonth +  + blogDay +  + blogYear",
  }

  let content
  let seo

  if (blog.edges[0] != undefined) {
    // Blog Content
    content = blog.edges[0].node

    bannerContent = {
      categories: categoryString,
      title: content.title[0].text,
      author: content.author
        ? content.author.first_name + " " + content.author.last_name
        : "",
      date: content.date_created,
    }

    seo = {
      title: content.meta_title,
      description: content.meta_description,
      image: content.social_image ? content.social_image.url : null,
      pathname: "/resources/blog/" + content._meta.uid,
      article: true,
    }
  }

  // console.log(props.data)

  return (
    <Layout>
      <SEO {...seo} />
      <Banner bannerContent={bannerContent} />
      <section className="blog_area_two">
        <div className="container" style={{ maxWidth: '950px' }}>
          <div className="row">
            <div className="blog_single_info">
              <div className="blog_list_item blog_list_item_two">
                <div className="blog_content">

                  {/* Blog Content (RichText, Qoute & Images) */}

                  {props.data.blogDetail.allBlogs.edges[0].node.body.map(
                    (blog, i) => {
                      return (
                        <>
                          {blog.primary.richtext ? (
                            <RichText render={blog.primary.richtext} />
                          ) : null}
                          {blog.primary.text ? (
                            <blockquote>
                              <h6>{RichText.asText(blog.primary.text)}</h6>
                              {blog.primary.author1 ? (
                                <p className="author_name">
                                  {blog.primary.author1}
                                </p>
                              ) : null}
                            </blockquote>
                          ) : null}
                          {blog.primary.image ? (
                            <img
                              style={{
                                padding: "0px 0px 30px 0px"
                              }}
                              className="blog_images"
                              src={blog.primary.image.url}
                              alt={blog.primary.image.alt}
                            />
                          ) : null}
                          {blog.primary.cta_text ? (
                            <section id="blog_call_to_action">
                              <RichText render={blog.primary.cta_text} />
                              <a href={blog.primary.cta_link.url}><button className="btn_hover btn_blog">{blog.primary.button_title}</button></a>
                            </section>
                          ) : null}
                           {blog.primary.bottom_cta_text ? (
                            <section id="bottom_call_to_action">
                              <RichText render={blog.primary.bottom_cta_text} />
                            </section>
                          ) : null}
                        </>
                      )
                    }
                  )}

                  {/* Enhanced Pagination */}

                  <EnhancedPagination
                    prevArticle={prevArticle}
                    nextArticle={nextArticle}
                  />

                  {/* Share Buttons */}

                  <div className="post-info-bottom">
                    <div className="social_icon">
                      share:
                     
                          <EmailShareButton
                            url={props.location.href}
                            subject={content ? content.title[0].text : ""}
                          >
                            <span className="icon" style={{marginLeft: '0.5rem'}}>
                              <EmailIcon size={32} round={true} />
                            </span>
                          </EmailShareButton>
                      
                          <FacebookShareButton url={props.location.href}>
                            <span className="icon" style={{marginLeft: '0.5rem'}}>
                              <FacebookIcon size={32} round={true} />
                            </span>
                          </FacebookShareButton>
                    
                          <LinkedinShareButton
                            url={props.location.href}
                            title={content ? content.title[0].text : ""}
                          >
                            <span className="icon" style={{marginLeft: '0.5rem'}}>
                              <LinkedinIcon size={32} round={true} />
                            </span>
                          </LinkedinShareButton>
                      
                          <TwitterShareButton
                            url={props.location.href}
                            title={content ? content.title[0].text : ""}
                            via={props.data.seo.siteMetadata.twitterUsername
                              .split("@")
                              .join("")}
                          >
                            <span className="icon" style={{marginLeft: '0.5rem'}}>
                              <TwitterIcon size={32} round={true} />
                            </span>
                          </TwitterShareButton>
                     
                    </div>
                  </div>
                </div>
              </div>

              {/* Commenting */}

              <Disqus
                style={{ margin: "50px auto" }}
                config={{
                  url: props.data.blogDetail.allBlogs.edges[0].node._meta.id,
                  identifier:
                    props.data.blogDetail.allBlogs.edges[0].node._meta.uid,
                  title:
                    props.data.blogDetail.allBlogs.edges[0].node.title[0].text,
                }}
              />

              {/* Author Workhub Internal Logic */}

              {content ? (
                content.author ? (
                  <div className="media post_author_two">
                    {content.author ? (
                      <img
                        className="img_rounded"
                        src={content.author.social_image.url}
                        alt="Workhub Logo"
                      />
                    ) : (
                        ""
                      )}

                    <div className="media-body">
                      <div className="comment_info">
                        <h3>
                          {content
                            ? content.author.first_name +
                            " " +
                            content.author.last_name
                            : ""}
                        </h3>
                      </div>
                      <p>{content.author.description}</p>
                    </div>
                  </div>
                ) : (
                    ""
                  )
              ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Blog

/* Replace PAGE_URL with your post's canonical URL variable */
/* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
/* Replace PAGE_TITLE with the title of the page */
